export const experienceData = [
    // {
    //     id: 1,
    //     company: 'Quantiphi Analytical Solutions Ptv.Ltd',
    //     jobtitle: 'Frame Work Engineer',
    //     startYear: '2022',
    //     endYear: '2023'
    // },
    {
        id: 2,
        company: 'Sai Ganapathi Polythenic',
        jobtitle: 'Project Lead (Project - Sai Ganapathi Web Application)',
        startYear: 'July 2023 ',
        endYear: ' August 2023'
    },
    {
        id: 3,
        company: 'Learning New Technology For Everyday',
        jobtitle: 'Self Learning',
        startYear: '2021',
        endYear: 'Present'
    },
]